table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    /* min-width: 500px; */
    border-collapse: collapse;
  }

  table .group{
      border:2px solid black;
  }

  .group{
    background-color: black;
  }

  tr.group,
  tr.group:hover {
    background-color: #ddd !important;
  }