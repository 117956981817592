@import url(//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en);
/* MARGINS & PADDINGS */
.p-xxs {
  padding: 5px !important;
}
.p-xs {
  padding: 10px !important;
}
.p-sm {
  padding: 15px !important;
}
.p-m {
  padding: 20px !important;
}
.p-md {
  padding: 25px !important;
}
.p-lg {
  padding: 30px !important;
}
.p-xl {
  padding: 40px !important;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-t-none {
  margin-top: 0;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-xxl {
  margin-top: 50px;
}
.m-t-xxxl {
  margin-top: 60px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-r-none {
  margin-right: 0;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.space-15 {
  margin: 15px 0;
}
.space-20 {
  margin: 20px 0;
}
.space-25 {
  margin: 25px 0;
}
.space-30 {
  margin: 30px 0;
}
.sweet-alert button {
  background-color: #266dab;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer;
}
.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.sweet-alert button:hover {
  background-color: #a1d9f2;
}
.sweet-alert button:active {
  background-color: #81ccee;
}
.sweet-alert button.cancel {
  background-color: #D0D0D0;
}
.sweet-alert button.cancel:hover {
  background-color: #c8c8c8;
}
.sweet-alert button.cancel:active {
  background-color: #b6b6b6;
}
.sweet-alert p {
  font-size: 14px;
  font-weight: 400;
}
.sweet-alert h2 {
  font-size: 26px;
}
.sweet-alert {
  padding: 25px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
}
.table > thead > tr > th {
  border-bottom: 1px solid #ddd;
}
.cg-notify-message.homer-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  border: none ;
  margin-top: 30px;
  color: inherit;
}
.homer-notify.alert-warning {
  border-left: 6px solid #ffb606;
}
.homer-notify.alert-success {
  border-left: 6px solid #62cb31;
}
.homer-notify.alert-danger {
  border-left: 6px solid #c0392b;
}
.homer-notify.alert-info {
  border-left: 6px solid #268dcd;
}
.h-modal-icon {
  font-size: 66px;
}
.inmodal .color-line {
  border-radius: 4px 4px 0 0;
}
.modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}
.modal-backdrop.in {
  opacity: 0.4;
}
.modal-backdrop {
  position: fixed;
  bottom: 0;
}
.modal-dialog {
  margin-top: 80px;
}
.modal-title {
  font-size: 30px;
  font-weight: 300;
}
.modal-header {
  /* padding: 40px 30px; */
  background: #f7f9fa;
}
.modal-body {
  padding: 20px 30px;
}
.modal-header small {
  color: #9d9fa2;
}
.modal-footer {
  background: #f7f9fa;
}
.hmodal-info .color-line {
  background: #268dcd;
}
.hmodal-warning .color-line {
  background: #ffb606;
}
.hmodal-success .color-line {
  background: #62cb31;
}
.hmodal-danger .color-line {
  background: #c0392b;
}
.hmodal-info h4.modal-title {
  color: #268dcd;
}
.hmodal-warning h4.modal-title {
  color: #ffb606;
}
.hmodal-success h4.modal-title {
  color: #62cb31;
}
.hmodal-danger h4.modal-title {
  color: #c0392b;
}
/* Progress bar */
.progress {
  border-radius: 2px;
  margin-bottom: 10px;
}
.progress-bar {
  background-color: #f1f3f6;
  text-align: right;
  padding-right: 10px;
  color: #6a6c6f;
}
.progress-small,
.progress-small .progress-bar {
  height: 10px;
}
.progress-bar-success {
  border-right: 4px solid #58b62c;
}
.progress-bar-info {
  border-right: 4px solid #258cd1;
}
.progress-bar-warning {
  border-right: 4px solid #eba600;
}
.progress-bar-danger {
  border-right: 4px solid #e43725;
}
.full .progress-bar-success {
  background-color: #62cb31;
  border-right: 4px solid #58b62c;
}
.full .progress-bar-info {
  background-color: #268dcd;
  border-right: 4px solid #258cd1;
}
.full .progress-bar-warning {
  background-color: #ffb606;
  border-right: 4px solid #eba600;
}
.full .progress-bar-danger {
  background-color: #e74c3c;
  border-right: 4px solid #e43725;
}
.full .progress-bar {
  color: #ffffff;
}
body.blank {
  background-color: #f1f3f6;
}
.login-container {
  max-width: 420px;
  margin: auto;
  padding-top: 6%;
}
.register-container {
  max-width: 720px;
  margin: auto;
  padding-top: 6%;
}
.lock-container {
  max-width: 420px;
  margin: auto;
  padding-top: 6%;
}
.error-container {
  max-width: 620px;
  margin: auto;
  padding-top: 6%;
}
.big-icon {
  font-size: 120px;
}
.error-container h1 {
  font-size: 44px;
}
.back-link {
  float: left;
  width: 100%;
  margin: 10px;
}
@media (max-width: 420px) {
  .login-container,
  .lock-container {
    margin: auto 10px;
  }
}
@media (max-width: 720px) {
  .register-container {
    margin: auto 10px;
  }
  .lock-container {
    padding-top: 15%;
  }
}
@media (max-width: 620px) {
  .error-container {
    margin: auto 10px;
  }
}
.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 98%;
  margin: 0 auto;
}
.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}
.v-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
.v-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #e8ebf0;
}
.vertical-timeline-content .btn {
  float: right;
}
.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-block:first-child {
  margin-top: 0;
}
.vertical-timeline-block:last-child {
  margin-bottom: 0;
}
.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid #e4e5e7;
  text-align: center;
  background: #fff;
}
.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}
.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  border: 1px solid #e4e5e7;
}
.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}
.vertical-timeline-content p {
  margin: 1em 0 0 0;
  line-height: 1.6;
}
.vertical-timeline-content .vertical-date {
  font-weight: 500;
  text-align: right;
  font-size: 13px;
}
.vertical-date small {
  color: #62cb31;
  font-weight: 400;
}
.vertical-timeline-content:after,
.vertical-timeline-content:before {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.vertical-timeline-content:after {
  border-color: transparent;
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}
.vertical-timeline-content:before {
  border-color: transparent;
  border-right-color: #e4e5e7;
  border-width: 11px;
  margin-top: -11px;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }
  .vertical-timeline-content p {
    font-size: 13px;
  }
}
.contact-panel h3 {
  margin-bottom: 2px;
}
.contact-panel h3 a {
  color: inherit;
}
.contact-panel p {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 0;
}
.contact-panel img {
  width: 76px;
  height: 76px;
}
.contact-footer {
  padding: 0 15px;
}
.contact-stat span {
  font-size: 10px;
  font-weight: 500;
  display: block;
  color: #9d9fa2;
  text-transform: uppercase;
}
.contact-stat {
  padding: 8px 0px;
  text-align: center;
}
.contact-panel:hover .panel-body {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: pulse;
  -webkit-animation-name: pulse;
  z-index: 1;
}
/* FULLCALENDAR */
.fc-state-default {
  background-color: #ffffff;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: inherit;
  text-shadow: none;
}
.fc-state-default {
  border: 1px solid;
}
.fc-button {
  color: inherit;
  border: 1px solid #e4e5e7;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap;
}
.fc-state-active {
  background-color: #266dab;
  border-color: #266dab;
  color: #ffffff;
}
.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}
.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
  border-color: #e4e5e7;
  font-weight: normal;
}
.fc-border-separate tbody {
  background-color: #F8F8F8;
}
.fc-state-highlight {
  background: none repeat scroll 0 0 #f1f3f6;
}
.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}
.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px;
}
.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: #266dab;
  border-color: #266dab;
}
.fc-event-time,
.fc-event-title {
  color: #717171;
  padding: 0 1px;
}
.ui-calendar .fc-event-time,
.ui-calendar .fc-event-title {
  color: #fff;
}
.fc-view-container .fc-widget-header {
  background: #f7f8fa;
}
.fc-widget-header .fc-day-header {
  padding: 12px 0;
}
.fc-ltr .fc-basic-view .fc-day-number {
  text-align: center;
}
.fc-basic-view td.fc-day-number,
.fc-basic-view td.fc-week-number span {
  padding: 6px;
}
.fc-day-grid-event {
  margin: 2px 6px 0;
}
.note-editor {
  border: 1px solid #e4e5e7;
}
.note-editor .note-toolbar {
  border-bottom: 1px solid #e4e5e7;
}
.note-editor .btn-default {
  border-color: #e4e5e7;
}
.ngH eaderCell {
  border-right: none;
  border-bottom: 1px solid #e4e5e7;
}
.ngCell {
  border-right: none;
}
.ngTopPanel {
  background: #fff;
}
.ngRow.even {
  background: #f9f9f9;
}
.ngRow.selected {
  background: #e5e8ee;
}
.ngRow {
  border-bottom: 1px solid #e4e5e7;
}
.ngCell {
  background-color: transparent;
}
.ngHeaderCell {
  border-right: none;
}
.gridStyle {
  border: 1px solid #e4e5e7;
  height: 400px;
}
.show-grid [class^="col-"] {
  background-color: #f1f3f6 !important;
  border: 1px solid #ddd;
  padding-bottom: 10px;
  padding-top: 10px;
}
.show-grid {
  margin: 15px 0;
}
.dropdown-menu.hdropdown {
  padding: 0;
  z-index: 1060;
}
.dropdown-menu.hdropdown .title {
  padding: 12px 12px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu.hdropdown li {
  padding: 6px 12px;
  text-align: left;
  background: #fafbfc;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu.filedropdown li {
  padding: 6px 6px;
  text-align: left;
  background: #fafbfc;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu.filedropdown li i {
  margin-right: 10px;
}
.dropdown-menu.hdropdown.notification li {
  padding: 12px 12px;
}
.dropdown-menu.hdropdown.notification li .label {
  margin-right: 10px;
}
.dropdown-menu.hdropdown li > a:hover,
.dropdown-menu.filedropdown li > a:hover {
  background: inherit;
}
.dropdown-menu.hdropdown li:hover,
.dropdown-menu.filedropdown li:hover {
  background: #f1f3f6;
}
.dropdown-menu.hdropdown li.summary {
  padding: 6px 12px;
  text-align: center;
  background: #fff;
}
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav .open > a,
.navbar-nav .open > a:hover,
.navbar-nav .open > a:focus {
  color: inherit;
  background: #f7f8fa;
  border-bottom: 1px solid #e4e5e7;
}
.chat-avatar {
  width: 36px;
  height: 36px;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}
.chat-user-name {
  padding: 10px;
}
.chat-user {
  padding: 8px 10px;
  border-bottom: 1px solid #e7eaec;
}
.chat-user a {
  color: inherit;
}
.chat-view {
  z-index: 1070;
}
.chat-users,
.chat-statistic {
  margin-left: -30px;
}
@media (max-width: 992px) {
  .chat-users,
  .chat-statistic {
    margin-left: 0px;
  }
}
.chat-view .ibox-content {
  padding: 0;
}
.chat-message {
  padding: 10px 20px;
}
.message-avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-top: 1px;
}
.chat-discussion .chat-message .message-avatar {
  float: left;
  margin-right: 10px;
}
.chat-discussion .message {
  background-color: #f7f8fa;
  text-align: left;
  display: block;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px;
}
.chat-discussion .chat-message .message-date {
  float: right;
}
.chat-discussion .chat-message .message {
  text-align: left;
  margin-left: 55px;
}
.message-date {
  font-size: 10px;
  color: #888888;
}
.message-content {
  display: block;
}
.chat-users,
.chat-discussion {
  overflow-y: auto;
  height: 500px;
}
.chat-discussion .chat-message.left .message-date {
  float: right;
}
.chat-discussion .chat-message.right .message-date {
  float: left;
}
.chat-discussion .chat-message.left .message {
  text-align: left;
  margin-left: 55px;
}
.chat-discussion .chat-message.right .message {
  text-align: right;
  margin-right: 55px;
}
.chat-discussion .chat-message.left .message-avatar {
  float: left;
  margin-right: 10px;
}
.chat-discussion .chat-message.right .message-avatar {
  float: right;
  margin-left: 10px;
}
.chat-discussion {
  padding: 15px 10px;
}
.header-line.extreme-color {
  width: 20%;
}
.header-line {
  height: 6px;
  width: 10%;
  float: left;
  margin: 0;
  position: relative;
}
.header-line div {
  position: absolute;
  top: 70px;
  left: 15px;
  font-weight: 600;
  color: #fff;
}
.header-line div span {
  font-weight: 400;
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.color-demo .header-line {
  height: 118px;
}
.color-demo .header-line:after {
  display: block;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  height: 10px;
  width: 100%;
}
/* Buttons */
.btn {
  border-radius: 3px;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.btn-primary.btn-outline {
  color: #266dab;
}
.btn-primary2.btn-outline {
  color: #9b59b6;
}
.btn-success.btn-outline {
  color: #62cb31;
}
.btn-info.btn-outline {
  color: #268dcd;
}
.btn-warning.btn-outline {
  color: #ffb606;
}
.btn-warning2.btn-outline {
  color: #e67e22;
}
.btn-danger.btn-outline {
  color: #e74c3c;
}
.btn-danger2.btn-outline {
  color: #c0392b;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn-primary {
  background-color: #266dab;
  border-color: #266dab;
  color: #FFFFFF ;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #3f5872;
  border-color: #3f5872;
  color: #FFFFFF ;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #293a4a;
  border-color: #293a4a;
}
.btn-primary2 {
  background-color: #9b59b6;
  border-color: #9b59b6;
  color: #FFFFFF ;
}
.btn-primary2:hover,
.btn-primary2:focus,
.btn-primary2:active,
.btn-primary2.active,
.open .dropdown-toggle.btn-primary2 {
  background-color: #a86ebf;
  border-color: #a86ebf;
  color: #FFFFFF ;
}
.btn-primary2:active,
.btn-primary2.active,
.open .dropdown-toggle.btn-primary2 {
  background-image: none;
}
.btn-primary2.disabled,
.btn-primary2.disabled:hover,
.btn-primary2.disabled:focus,
.btn-primary2.disabled:active,
.btn-primary2.disabled.active,
.btn-primary2[disabled],
.btn-primary2[disabled]:hover,
.btn-primary2[disabled]:focus,
.btn-primary2[disabled]:active,
.btn-primary2.active[disabled],
fieldset[disabled] .btn-primary2,
fieldset[disabled] .btn-primary2:hover,
fieldset[disabled] .btn-primary2:focus,
fieldset[disabled] .btn-primary2:active,
fieldset[disabled] .btn-primary2.active {
  background-color: #8c49a7;
  border-color: #8c49a7;
}
.btn-success {
  background-color: #62cb31;
  border-color: #62cb31;
  color: #FFFFFF ;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #74d348;
  border-color: #74d348;
  color: #FFFFFF ;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success.active[disabled],
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #56b22b;
  border-color: #56b22b;
}
.btn-info {
  background-color: #268dcd;
  border-color: #268dcd;
  color: #FFFFFF ;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #4ea5e0;
  border-color: #4ea5e0;
  color: #FFFFFF ;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info.active[disabled],
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #2489cc;
  border-color: #2489cc;
}
.btn-default {
  background-color: #fff;
  border-color: #e4e5e7;
  color: #6a6c6f;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-color: #fff;
  border-color: #d9dbdd;
  color: #606265;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #e4ebee;
  border-color: #e4ebee;
}
.btn-warning {
  background-color: #ffb606;
  border-color: #ffb606;
  color: #FFFFFF ;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #ffbf25;
  border-color: #ffbf25;
  color: #FFFFFF ;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning.active[disabled],
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #e6a300;
  border-color: #e6a300;
}
.btn-warning2 {
  background-color: #e67e22;
  border-color: #e67e22;
  color: #FFFFFF ;
}
.btn-warning2:hover,
.btn-warning2:focus,
.btn-warning2:active,
.btn-warning2.active,
.open .dropdown-toggle.btn-warning2 {
  background-color: #e98e3d;
  border-color: #e98e3d;
  color: #FFFFFF ;
}
.btn-warning2:active,
.btn-warning2.active,
.open .dropdown-toggle.btn-warning2 {
  background-image: none;
}
.btn-warning2.disabled,
.btn-warning2.disabled:hover,
.btn-warning2.disabled:focus,
.btn-warning2.disabled:active,
.btn-warning2.disabled.active,
.btn-warning2[disabled],
.btn-warning2[disabled]:hover,
.btn-warning2[disabled]:focus,
.btn-warning2[disabled]:active,
.btn-warning2.active[disabled],
fieldset[disabled] .btn-warning2,
fieldset[disabled] .btn-warning2:hover,
fieldset[disabled] .btn-warning2:focus,
fieldset[disabled] .btn-warning2:active,
fieldset[disabled] .btn-warning2.active {
  background-color: #d26f18;
  border-color: #d26f18;
}
.btn-danger {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: #FFFFFF ;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #ea6557;
  border-color: #ea6557;
  color: #FFFFFF ;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger.active[disabled],
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #e43321;
  border-color: #e43321;
}
.btn-danger2 {
  background-color: #c0392b;
  border-color: #c0392b;
  color: #FFFFFF ;
}
.btn-danger2:hover,
.btn-danger2:focus,
.btn-danger2:active,
.btn-danger2.active,
.open .dropdown-toggle.btn-danger2 {
  background-color: #d24637;
  border-color: #d24637;
  color: #FFFFFF ;
}
.btn-danger2:active,
.btn-danger2.active,
.open .dropdown-toggle.btn-danger2 {
  background-image: none;
}
.btn-danger2.disabled,
.btn-danger2.disabled:hover,
.btn-danger2.disabled:focus,
.btn-danger2.disabled:active,
.btn-danger2.disabled.active,
.btn-danger2[disabled],
.btn-danger2[disabled]:hover,
.btn-danger2[disabled]:focus,
.btn-danger2[disabled]:active,
.btn-danger2.active[disabled],
fieldset[disabled] .btn-danger2,
fieldset[disabled] .btn-danger2:hover,
fieldset[disabled] .btn-danger2:focus,
fieldset[disabled] .btn-danger2:active,
fieldset[disabled] .btn-danger2.active {
  background-color: #a73225;
  border-color: #a73225;
}
.btn-link {
  color: inherit ;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  color: #6a6c6f;
}
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  background-image: none;
}
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #9d9fa2;
}
.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}
.btn-xs {
  border-radius: 3px;
  font-size: 11px;
  line-height: 1.5;
  padding: 1px 7px;
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}
.btn-circle.btn-xs {
  width: 20px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 25px;
  font-size: 10px;
  line-height: 1;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}
/* Fonts */
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-bold {
  font-weight: 600;
}
.font-extra-bold {
  font-weight: 700;
}
.font-uppercase {
  text-transform: uppercase;
}
.font-trans {
  opacity: 0.5 !important;
}
.text-big {
  font-size: 60px;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 200;
}
/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(img/green.png) no-repeat;
  border: none;
  cursor: pointer;
}
.icheckbox_square-green {
  background-position: 0 0;
}
.icheckbox_square-green.hover {
  background-position: -24px 0;
}
.icheckbox_square-green.checked {
  background-position: -48px 0;
}
.icheckbox_square-green.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-green.checked.disabled {
  background-position: -96px 0;
}
.iradio_square-green {
  background-position: -120px 0;
}
.iradio_square-green.hover {
  background-position: -144px 0;
}
.iradio_square-green.checked {
  background-position: -168px 0;
}
.iradio_square-green.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-green.checked.disabled {
  background-position: -216px 0;
}
/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-green,
  .iradio_square-green {
    background-image: url(img/green@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}
/* Layout */
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden!important;
}
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  font-size: 13px;
  color: #6a6c6f;
  margin: 0;
  padding: 0;
  height: 100%;
}
#header {
  background-color: #fff;
  display: block;
  height: 62px;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: 1px solid #e4e5e7;
}
#menu {
  width: 180px;
  bottom: 0;
  float: left;
  left: 0;
  position: absolute;
  top: 62px;
  transition: all 0.4s ease 0s;
}
#wrapper {
  margin: 0 0px 0 180px;
  padding: 0;
  background: #f1f3f6;
  border-left: 1px solid #e4e5e7;
  transition: all 0.4s ease 0s;
  position: relative;
  min-height: 100%;
}
.content {
  padding: 25px 40px 40px 40px;
  min-width: 320px;
}
.footer {
  padding: 10px 18px;
  background: #ffffff;
  border-top: 1px solid #e4e5e7;
  transition: all 0.4s ease 0s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .content {
    padding: 15px 15px 15px 15px;
    min-width: 320px;
  }
}
.content-boxed {
  max-width: 1100px;
  margin: auto;
  padding-top: 60px;
}
#page-header {
  padding: 20px 40px;
}
/*color theme change*/
.color-line {
  background: #f7f9fa;
  height: 6px;
  /*background-image: -webkit-linear-gradient(left, #266DAB, #266DAB 25%, #268DCD 25%, #268DCD 35%, #1DB7D1 35%, #1DB7D1 45%, #9AB420 45%, #9AB420 55%, #F7A431 55%, #F7A431 65%, #F08037 65%, #F08037 75%, #E83559 85%, #E83559 85%, #B53459 85%, #B53459 100%);
  background-image: -moz-linear-gradient(left, #266DAB, #266DAB 25%, #268DCD 25%, #268DCD 35%, #1DB7D1 35%, #1DB7D1 45%, #9AB420 45%, #9AB420 55%, #F7A431 55%, #F7A431 65%, #F08037 65%, #F08037 75%, #E83559 85%, #E83559 85%, #B53459 85%, #B53459 100%);
  background-image: -ms-linear-gradient(left, #266DAB, #266DAB 25%, #268DCD 25%, #268DCD 35%, #1DB7D1 35%, #1DB7D1 45%, #9AB420 45%, #9AB420 55%, #F7A431 55%, #F7A431 65%, #F08037 65%, #F08037 75%, #E83559 85%, #E83559 85%, #B53459 85%, #B53459 100%);
  background-image: linear-gradient(to right, #266DAB, #266DAB 25%, #268DCD 25%, #268DCD 35%, #1DB7D1 35%, #1DB7D1 45%, #9AB420 45%, #9AB420 55%, #F7A431 55%, #F7A431 65%, #F08037 65%, #F08037 75%, #E83559 85%, #E83559 85%, #B53459 85%, #B53459 100%);*/
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background: rgba(38,109,171,1);
  background: -moz-linear-gradient(left, rgba(38,109,171,1) 0%, rgba(38,141,205,1) 18%, rgba(29,183,209,1) 34%, rgba(154,180,32,1) 53%, rgba(247,164,49,1) 66%, rgba(240,128,55,1) 77%, rgba(232,53,89,1) 87%, rgba(181,52,89,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(38,109,171,1)), color-stop(18%, rgba(38,141,205,1)), color-stop(34%, rgba(29,183,209,1)), color-stop(53%, rgba(154,180,32,1)), color-stop(66%, rgba(247,164,49,1)), color-stop(77%, rgba(240,128,55,1)), color-stop(87%, rgba(232,53,89,1)), color-stop(100%, rgba(181,52,89,1)));
  background: -webkit-linear-gradient(left, rgba(38,109,171,1) 0%, rgba(38,141,205,1) 18%, rgba(29,183,209,1) 34%, rgba(154,180,32,1) 53%, rgba(247,164,49,1) 66%, rgba(240,128,55,1) 77%, rgba(232,53,89,1) 87%, rgba(181,52,89,1) 100%);
  background: -o-linear-gradient(left, rgba(38,109,171,1) 0%, rgba(38,141,205,1) 18%, rgba(29,183,209,1) 34%, rgba(154,180,32,1) 53%, rgba(247,164,49,1) 66%, rgba(240,128,55,1) 77%, rgba(232,53,89,1) 87%, rgba(181,52,89,1) 100%);
  background: -ms-linear-gradient(left, rgba(38,109,171,1) 0%, rgba(38,141,205,1) 18%, rgba(29,183,209,1) 34%, rgba(154,180,32,1) 53%, rgba(247,164,49,1) 66%, rgba(240,128,55,1) 77%, rgba(232,53,89,1) 87%, rgba(181,52,89,1) 100%);
  background: linear-gradient(to right, rgba(38,109,171,1) 0%, rgba(38,141,205,1) 18%, rgba(29,183,209,1) 34%, rgba(154,180,32,1) 53%, rgba(247,164,49,1) 66%, rgba(240,128,55,1) 77%, rgba(232,53,89,1) 87%, rgba(181,52,89,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#266dab', endColorstr='#b53459', GradientType=1 );
}
.fixed-navbar #header {
  width: 100%;
  position: fixed;
  z-index: 1000;
}
.fixed-navbar #wrapper {
  top: 62px;
}
.fixed-sidebar #menu {
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 197px;
  margin-right: -17px;
}
.fixed-sidebar.show-sidebar #menu {
  width: 180px;
}
.fixed-footer .footer {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 181px;
  right: 0;
}
.fixed-footer.hide-sidebar .footer {
  left: 0;
}
@media (max-width: 768px) {
  .fixed-footer .footer {
    left: 0;
  }
  .fixed-footer.show-sidebar .footer {
    left: 181px;
  }
}
#right-sidebar {
  background-color: #fff;
  border-left: 1px solid #e4e5e7;
  overflow: hidden;
  position: fixed;
  top: 0px;
  width: 260px !important;
  z-index: 1009;
  bottom: 0;
  right: 0;
  display: none;
  overflow: auto;
}
#right-sidebar.sidebar-open {
  display: block;
}
.fixed-small-header .small-header {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  margin-left: 181px;
  z-index: 1001;
}
.fixed-small-header .content {
  padding-top: 80px;
}
.fixed-small-header.hide-sidebar .small-header {
  margin-left: 0;
}
.fixed-small-header.page-small .small-header {
  margin-left: 0;
}
.fixed-small-header.page-small .content {
  padding-top: 120px;
}
.fixed-small-header.page-small.show-sidebar .small-header {
  margin-left: 181px;
  overflow: hidden;
}
/* Lists */
.list .list-item-container {
  float: left;
}
.list-item-container div:first-child {
  border-top: none;
}
.list .list-item .list-item-content p {
  margin-bottom: 3px;
  margin-top: 0;
}
.list .list-item-date {
  color: #AAAAAA;
  font-size: 10px;
  text-align: center;
}
.list .list-item {
  float: left;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #e4e5e7;
}
.list .list-item-container-small {
  min-width: 80px;
  text-align: center;
}
.list .list-item .list-item-content .list-item-right {
  height: 100%;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.list .list-item .list-item-content h1,
.list .list-item .list-item-content h2,
.list .list-item .list-item-content h3,
.list .list-item .list-item-content h4,
.list .list-item .list-item-content h5,
.list .list-item .list-item-content h6,
.list .list-item .list-item-content .list-item-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
/* Panels */
.hpanel > .panel-heading {
  color: inherit;
  font-weight: 600;
  padding: 10px 4px;
  transition: all .3s;
  border: 1px solid transparent;
}
.hpanel .hbuilt.panel-heading {
  border-bottom: none;
}
.hpanel > .panel-footer {
  color: inherit;
  border: 1px solid #e4e5e7;
  border-top: none;
  font-size: 90%;
  background: #f7f9fa;
}
.hpanel.panel-collapse > .panel-heading,
.hpanel .hbuilt {
  background: #fff;
  border-color: #e4e5e7;
  border: 1px solid #e4e5e7;
  padding: 10px 10px;
  border-radius: 2px;
}
.hpanel .panel-body {
  background: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 20px; /*added more side padding 05102017*/ /*removed side padding 03112017*/
  position: relative;
}
.panel-collapse .panel-body {
  border: none;
}
.hpanel {
  background-color: none;
  border: none;
  box-shadow: none;
  margin-bottom: 25px;
}
.panel-tools {
  display: inline-block;
  float: right;
  margin-top: 0;
  padding: 0;
  position: relative;
}
.hpanel .alert {
  margin-bottom: 0;
  border-radius: 0;
  border: 1px solid #e4e5e7;
  border-bottom: none;
}
.panel-tools a {
  margin-left: 5px;
  color: #9d9fa2;
  cursor: pointer;
}
.hpanel.hgreen .panel-body {
  border-top: 2px solid #62cb31;
}
.hpanel.hblue .panel-body {
  border-top: 2px solid #268dcd;
}
.hpanel.hyellow .panel-body {
  border-top: 2px solid #ffb606;
}
.hpanel.hviolet .panel-body {
  border-top: 2px solid #9b59b6;
}
.hpanel.horange .panel-body {
  border-top: 2px solid #e67e22;
}
.hpanel.hred .panel-body {
  border-top: 2px solid #e74c3c;
}
.hpanel.hreddeep .panel-body {
  border-top: 2px solid #c0392b;
}
.hpanel.hnavyblue .panel-body {
  border-top: 2px solid #266dab;
}
.hpanel.hbggreen .panel-body {
  background: #62cb31;
  color: #fff;
  border: none;
}
.hpanel.hbgblue .panel-body {
  background: #268dcd;
  color: #fff;
  border: none;
}
.hpanel.hbgyellow .panel-body {
  background: #ffb606;
  color: #fff;
  border: none;
}
.hpanel.hbgviolet .panel-body {
  background: #9b59b6;
  color: #fff;
  border: none;
}
.hpanel.hbgorange .panel-body {
  background: #e67e22;
  color: #fff;
  border: none;
}
.hpanel.hbgred .panel-body {
  background: #e74c3c;
  color: #fff;
  border: none;
}
.hpanel.hbgreddeep .panel-body {
  background: #c0392b;
  color: #fff;
  border: none;
}
.hpanel.hbgnavyblue .panel-body {
  background: #266dab;
  color: #fff;
  border: none;
}
.panel-group .panel-heading {
  background-color: #f7f9fa;
}
.small-header .hpanel {
  margin-bottom: 0;
}
.small-header {
  padding: 0 !important;
}
.small-header .panel-body {
  padding: 15px 25px;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0;
}
.panel-body h5,
.panel-body h4 {
  font-weight: 600;
}
.small-header .panel-body h2 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0 0;
}
.small-header .panel-body small {
  color: #838589;
}
.hbreadcrumb {
  padding: 2px 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #fff;
  border-radius: #fff;
  font-size: 11px;
}
.hbreadcrumb > li {
  display: inline-block;
}
.hbreadcrumb > li + li:before {
  padding: 0 5px;
  color: #266dab;
}
.hbreadcrumb > .active {
  color: #9d9fa2;
}
.wrapper {
  padding: 10px 20px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border: 1px solid #e4e5e7;
  border-bottom-color: transparent;
}
.nav-tabs {
  border-bottom: 1px solid #e4e5e7;
}
.tab-pane .panel-body {
  border-top: none;
}
.tabs-left .tab-pane .panel-body,
.tabs-right .tab-pane .panel-body {
  border-top: 1px solid #e4e5e7;
}
.nav-tabs > li a:hover {
  background: transparent;
  border-color: transparent;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tabs-left .panel-body {
  position: static;
}
.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
  width: 20%;
}
.tabs-left .panel-body {
  width: 80%;
  margin-left: 20%;
}
.tabs-right .panel-body {
  width: 80%;
  margin-right: 20%;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #e4e5e7;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #e4e5e7;
  border-bottom-color: transparent;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #e4e5e7 transparent #e4e5e7 #e4e5e7;
  *border-right-color: #ffffff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #e4e5e7 #e4e5e7 #e4e5e7 transparent;
  *border-left-color: #ffffff;
  z-index: 1;
}
.tooltip-inner {
  background-color: #266dab;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #266dab;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #266dab;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #266dab;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #266dab;
}
/* Opacity */
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}
/* Google Maps */
.google-map {
  height: 300px;
}
.hr-line-dashed {
  background-color: transparent;
  border-top: 1px dotted #f1f3f6;
  color: transparent;
  height: 1px;
  margin: 20px 0;
}
/* Mixin */
.animated-panel {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* Color */
.h-bg-navy-blue {
  background: #266dab;
}
.h-bg-violet {
  background: #9b59b6;
}
.h-bg-blue {
  background: #268dcd;
}
.h-bg-green {
  background: #62cb31;
}
.h-bg-yellow {
  background: #ffb606;
}
.h-bg-orange {
  background: #e67e22;
}
.h-bg-red {
  background: #e74c3c;
}
.h-bg-red-deep {
  background: #c0392b;
}
.text-primary {
  color: #266dab;
}
.text-primary-2 {
  color: #9b59b6;
}
.text-info {
  color: #268dcd;
}
.text-success {
  color: #62cb31;
}
.text-warning {
  color: #ffb606;
}
.text-danger {
  color: #e74c3c;
}
.text-white {
  color: #fff;
}
.border-top {
  border-top: 1px solid #e4e5e7;
}
.border-bottom {
  border-bottom: 1px solid #e4e5e7;
}
.border-right {
  border-right: 1px solid #e4e5e7;
}
.border-left {
  border-left: 1px solid #e4e5e7;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/* Inputs */
.has-warning .form-control,
.has-error .form-control,
.has-success .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control {
  border-color: #d62c1a !important;
}
.has-warning .form-control {
  border-color: #d29400 !important;
}
.has-success .form-control {
  border-color: #4ea227 !important;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #d62c1a;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #d29400;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #4ea227;
}
/* Backgrounds */
.bg-white {
  background-color: #fff;
}
.bg-light-soft {
  background-color: #f1f3f6;
}
.bg-light {
  background-color: #f7f9fa;
}
/* Slider */
#logo {
  float: left;
  width: 180px;
  background-color: #266dab;
  padding: 18px 10px 18px 18px;
  height: 56px;
  text-align: center;
}
#logo span {
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}
#logo.light-version {
  background-color: #f7f9fa;
  border-bottom: 1px solid #e4e5e7;
  text-align: left;
}
#logo.light-version span {
  font-weight: 600;
  color: #266dab;
  font-size: 14px;
}
.profile-picture {
  padding: 20px 20px;
  text-align: center;
}
.stats-box div {
  display: inline-block;
  vertical-align: middle;
}
.stats-label {
  line-height: 1.2;
}
.stats-label small {
  font-size: 80%;
  line-height: 0.6;
}
.stats-box-value {
  background: #ffffff;
}
.stats-box-detail {
  background: #266dab;
  color: #fff;
  position: relative;
}
/* Labels */
.label-menu-corner .label {
  font-size: 10px;
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 18px;
  top: 12px;
}
/* Menu */
#side-menu {
  background: #f7f9fa;
}
#side-menu li a {
  color: #6a6c6f;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px;
}
#side-menu li {
  border-bottom: 1px solid #e4e5e7;
}
#side-menu li:first-child {
  border-top: 1px solid #e4e5e7;
}
#side-menu li.active a,
#side-menu li:hover a {
  color: #266dab;
}
#side-menu li .nav-second-level li a {
  padding: 10px 10px 10px 30px;
  color: #6a6c6f;
  text-transform: none;
  font-weight: 600;
}
#side-menu li .nav-second-level li.active a {
  color: #266dab;
  background-color: #f7f9fa;
}
#side-menu.nav > li > a:hover,
#side-menu.nav > li > a:focus {
  background: #fff;
}
#side-menu li.active {
  background: #fff;
}
.arrow {
  float: right;
}
.fa.arrow:before {
  content: "\f104";
}
.active > a > .fa.arrow:before {
  content: "\f107";
}
#top-navigation {
  height: 56px;
  position: relative;
  float: left;
}
.navbar-static-top {
  margin: 0 0 0 180px;
}
.navbar.navbar-static-top a,
.nav.navbar-nav li a {
  color: #9d9fa2;
}
.header-link {
  padding: 18px 26px 17px 26px;
  font-size: 14px;
  float: left;
  border-right: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  margin-right: 15px;
  background: #f7f9fa;
  cursor: pointer;
}
.header-link:hover {
  background: #f1f4f6;
}
.navbar-nav > li,
.navbar-nav > li > a {
  height: 56px;
}
.navbar-nav > li > a {
  padding: 15px 25px;
  font-size: 25px;
}
.navbar-nav > li:last-child > a {
  padding-right: 35px;
}
.navbar.navbar-static-top .hide-menu {
  color: #9d9fa2;
}
.panel-title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
/* Box */
.box {
  padding: 40px;
}
.box-rb-border {
  border-right: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.box-b-border {
  border-bottom: 1px solid #e4e5e7;
}
.box-r-border {
  border-right: 1px solid #e4e5e7;
}
/* Height */
.h-100 {
  min-height: 100px;
}
.h-150 {
  min-height: 150px;
}
.h-200 {
  min-height: 200px;
}
.h-300 {
  min-height: 300px;
}
.h-400 {
  min-height: 400px;
}
.h-500 {
  min-height: 500px;
}
.h-600 {
  min-height: 600px;
}
/* Sidebar Hide */
body.hide-sidebar #menu {
  margin-left: -180px;
}
body.hide-sidebar #wrapper {
  margin-left: 0;
}
body.page-small #menu {
  margin-left: -180px;
}
body.page-small #wrapper {
  margin-left: 0;
}
body.page-small.show-sidebar #menu {
  margin-left: 0;
}
body.page-small.show-sidebar #wrapper {
  margin-left: 180px;
}
a {
  cursor: pointer;
  color: #266dab;
}
a:hover,
a:focus {
  color: #266dab;
  text-decoration: none;
}
.no-padding {
  padding: 0 !important;
}
.no-borders {
  border: none !important;
}
.no-margins {
  margin: 0 !important;
}
.no-background {
  background: transparent !important;
}
.navbar-form-custom {
  float: left;
  height: 52px;
  padding: 0;
  width: 200px;
}
.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 13px;
  height: 52px;
  margin: 0;
  z-index: 1060;
  padding: 0;
}
.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
}
.vertical-mid {
  display: table;
}
.vertical-mid * {
  display: table-cell;
  vertical-align: middle;
}
/* Labels */
.label-success {
  background-color: #62cb31;
}
/* LIST GROUP */
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #266dab;
  border-color: #266dab;
  color: #FFFFFF !important;
}
.list-group-item-heading {
  margin-top: 10px;
}
.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  line-height: inherit;
}
.list-group-item {
  background-color: inherit;
  border: 1px solid #e4e5e7;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}
.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}
.no-padding .list-group {
  margin-bottom: 0;
}
.well {
  background: #f7f9fa;
  box-shadow: none;
}
/* Stats */
.stats h4,
h5,
h6 {
  margin: 5px 0;
}
.stats-title h4 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
/* Widths */
.w-xs {
  min-width: 100px;
}
.w-sm {
  min-width: 120px;
}
.w-m {
  min-width: 140px;
}
.w-lg {
  min-width: 160px;
}
.w-xl {
  min-width: 180px;
}
.w-xxl {
  min-width: 200px;
}
/* Charts */
.flot-chart {
  display: block;
  height: 300px;
  position: relative;
}
.flot-chart-content {
  width: 100%;
  height: 100%;
}
.stats .flot-chart {
  height: 60px;
}
/* Messages */
.feed-activity-list .feed-element {
  border-bottom: 1px solid #E7EAEC;
}
.feed-element:first-child {
  margin-top: 0;
}
.feed-element {
  padding-bottom: 15px;
}
.feed-element,
.feed-element .media {
  margin-top: 15px;
}
.feed-element,
.media-body {
  overflow: hidden;
}
.feed-element > .pull-left {
  margin-right: 10px;
}
.feed-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}
.feed-element .well {
  border: 1px solid #E7EAEC;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}
.feed-element .actions {
  margin-top: 10px;
}
.feed-element .photos {
  margin: 10px 0;
}
.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}
.feed-element img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}
.small-logo {
  display: none;
  text-align: center;
  padding-top: 18px;
  font-size: 14px;
  font-weight: 800;
}
.img-small {
  width: 62px;
  height: 62px;
}
@media (max-width: 768px) {
  .navbar-form-custom,
  .navbar-right,
  #logo {
    display: none;
  }
  .navbar-static-top {
    margin: 0;
  }
  .small-logo {
    display: block;
    float: left;
  }
  .header-line div {
    display: none;
  }
  .color-demo .header-line {
    height: 48px;
  }
  #hbreadcrumb {
    float: none !important;
    margin-bottom: 10px;
    margin-top: 0;
  }
}
@media (max-width: 320px) {
  #wrapper {
    overflow: hidden;
  }
}
.canvass {
  -moz-transition: width 0.01s, height 0.01s;
  -webkit-transition: width 0.01s, height 0.01s;
  -o-transition: width 0.01s, height 0.01s;
  transition: width 0.01s, height 0.01s;
}
.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  padding: 10px 10px;
  vertical-align: middle;
}
.icons-box {
  padding: 20px;
}
.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.icons-box .infont {
  text-align: center;
  margin-bottom: 25px;
}
.icons-box .infont i {
  font-size: 35px;
  display: block;
  color: #676a6c;
}
.icons-box .infont span {
  color: #a6a8a9;
}
.icons-box .infont span {
  margin: 1px;
  display: block;
}
.fullpanel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1070;
  overflow-x: scroll;
  transition: all 2s;
}
.transition {
  transition: all 0.5s;
}
.normalheader .hpanel {
  margin-bottom: 0;
}
.normalheader {
  min-width: 320px;
  padding: 40px 40px 0px 40px;
}
@media (max-width: 768px) {
  .normalheader {
    padding: 15px 15px 0px 15px;
  }
}
.normalheader h2 {
  margin-top: 0px;
}
.input-xs {
  height: 20px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 500;
}
.clip-header i {
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.clip-header {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8px;
  background: #f7f9fa;
  padding: 4px 5px;
  border-left: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu {
  font-size: 13px;
  border-radius: 3px;
}
.dropdown-example {
  display: inline-block;
  margin: 0px 10px 10px 10px;
}
.dropdown-example .dropdown-menu {
  position: static;
  display: block;
}
.pagination-example .pagination {
  margin: 0 0 10px 0;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #268dcd;
  border-color: #258cd1;
  color: #fff;
  cursor: default;
  z-index: 2;
}
.hbreadcrumb a.active {
  color: #9d9fa2;
}
.form-control {
  border-color: #e4e5e7 !important;
}
.input-group-addon {
  background: #fafbfc;
  border-color: #e4e5e7 !important;
}
.sidebar-button {
  padding: 4px 10px 0 10px;
}
.sidebar-button i {
  font-size: 20px;
}
/* Splash style */
.splash {
  position: absolute;
  z-index: 2000;
  background: white;
  color: gray;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.splash-title {
  text-align: center;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px;
}
.splash-title h1 {
  font-size: 26px;
}
/* Checkbox styles */
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #266dab;
  border-color: #266dab;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #268dcd;
  border-color: #268dcd;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffb606;
  border-color: #ffb606;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #62cb31;
  border-color: #62cb31;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #266dab;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #266dab;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #266dab;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #e74c3c;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #e74c3c;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #e74c3c;
}
.radio-info input[type="radio"] + label::after {
  background-color: #268dcd;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #268dcd;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #268dcd;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #ffb606;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffb606;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffb606;
}
.radio-success input[type="radio"] + label::after {
  background-color: #62cb31;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #62cb31;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #62cb31;
}
.checkbox input:hover,
.radio input:hover {
  cursor: pointer;
}
/* UI Sortable */
.ui-sortable .panel-body {
  cursor: move;
}
.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #f7f9fa;
}
.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important;
}
/* Badge colors */
.badge.badge-success {
  background-color: #62cb31;
}
.badge.badge-info {
  background-color: #268dcd;
}
.badge.badge-danger {
  background-color: #e74c3c;
}
.badge.badge-warning {
  background-color: #ffb606;
}
.badge.badge-primary {
  background-color: #266dab;
}
/* Tables */
.table > thead > tr > th {
  border-bottom: none;
}
.no-padding table {
  margin-bottom: 0;
}
/* Validation */
.form-control.error {
  border-color: #e74c3c !important;
}
label.error,
label span.error {
  color: #e74c3c;
  margin: 5px 0 0 0;
  font-weight: 400;
}
label span.error {
  margin-left: 5px;
}
label.error:before {
  content: "\f071";
  font-family: 'FontAwesome';
  margin: 0 4px;
}
/* File manager */
.hpanel .panel-body.file-body {
  padding: 30px 0;
  text-align: center;
}
.file-body i.fa {
  font-size: 56px;
}
/* Filter search */
.filter-item {
  margin-bottom: 0;
}
.filter-item .panel-body {
  padding: 10px 20px;
  border-bottom: none;
}
.filter-item a {
  color: #6a6c6f;
}
.filter-item:last-child {
  border-bottom: 1px solid #e4e5e7;
}
.filter-item:hover .panel-body,
.filter-item.active .panel-body {
  background: #fafcfd;
  z-index: 100;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175);
}
.filter-item::selection .panel-body {
  background: #fafcfd;
}
.filter-item h4 {
  margin-bottom: 5px;
}
/* Big menu */
.hdropdown.bigmenu {
  padding: 30px 30px 0px 30px;
}
.bigmenu a i {
  font-size: 46px;
}
.bigmenu a {
  display: block;
  text-align: center;
  margin: 0 10px 30px 0;
  min-width: 100px;
}
.bigmenu h5 {
  font-size: 13px;
  margin-top: 10px;
}
.projects .project-label {
  font-weight: 600;
}
.projects .hpanel .panel-body {
  border-bottom: none;
}
.projects .hpanel:last-child .panel-body {
  border-bottom: 1px solid #e4e5e7;
}
.projects .hpanel:last-child .panel-body {
  border-bottom: 1px solid #e4e5e7;
}
.projects .hpanel h4 a {
  color: #6a6c6f;
}
.projects .project-people img {
  width: 28px;
  height: 28px;
  margin-left: 2px;
  margin-bottom: 4px;
}
.projects .project-info {
  text-align: right;
}
@media (max-width: 768px) {
  .projects .project-info {
    text-align: left;
  }
}
.social-board .social-profile img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}
.social-board .social-profile h5 {
  margin-top: 5px;
  margin-bottom: 0px;
}
.social-board .social-form {
  margin: 10px 0;
}
.social-board .social-form input {
  font-size: 12px;
  padding: 2px 12px;
  height: 28px;
}
.social-talk .social-profile img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.social-talk .text-muted {
  color: #aaacae;
}
.social-talk {
  margin-bottom: 10px;
}
.social-talk .social-content {
  margin-top: 2px;
}
.angular-ui-tree-handle {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
  font-weight: normal;
}
.angular-ui-tree-handle:hover {
  font-weight: bold;
  cursor: pointer;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #e4e5e7;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.group-title {
  background-color: #687074 !important;
  color: #FFF !important;
}
.tree-node {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
  border-radius: 3px;
}
.tree-node .btn {
  min-width: 22px;
  margin-right: 4px;
}
.tree-node-content {
  margin: 5px 5px 5px 0;
}
.tree-handle {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #e4e5e7;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tour-backdrop {
  opacity: 0.5;
}
.blog-box p {
  font-size: 12px;
}
.blog-box h4 {
  font-weight: bold;
  margin-top: 0;
}
.blog-box .panel-heading {
  background: #fff;
  border-top: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 10px 20px;
  position: relative;
  font-weight: 500;
}
.blog-box .panel-footer {
  color: #909295;
}
.blog-box .panel-heading .media-body {
  line-height: 16px;
}
.blog-box .panel-heading .media img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.blog-box .panel-heading .media-body h5 {
  margin-top: 0;
}
.blog-box .panel-image {
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  position: relative;
}
.blog-box .panel-image .title {
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
  color: #fff;
}
.blog-box .panel-image .title h4 {
  margin-bottom: 0px;
}
.blog-box .panel-image .title a {
  color: #fff !important;
}
.blog-article-box .panel-heading {
  background: #fff;
  border-top: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 80px 10px;
  position: relative;
  font-weight: 500;
  text-align: center;
}
.blog-article-box .panel-heading h4 {
  font-size: 32px;
  font-weight: 700;
  color: #515355;
}
.blog-article-box .panel-body {
  padding: 50px 70px;
}
.blog-article-box .panel-body p {
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 20px;
  line-height: 32px;
  color: #515355;
}
@media (max-width: 768px) {
  .blog-article-box .panel-body {
    padding: 10px 20px;
  }
  .blog-article-box .panel-body p {
    font-family: "Times New Roman", Georgia, Serif;
    font-size: 16px;
    line-height: 28px;
  }
  .blog-article-box .panel-heading {
    padding: 20px 20px;
  }
}
.forum-box .panel-body {
  border-bottom: none;
}
.forum-box .panel-body:last-child {
  border-bottom: 1px solid #e4e5e7;
}
.forum-box .forum-heading h4 {
  margin-bottom: 3px;
  font-weight: 600;
  margin-top: 5px;
  display: inline;
}
.forum-box .forum-heading .label {
  margin-right: 5px;
}
.forum-box .forum-heading .desc {
  color: #909295;
  font-size: 12px;
}
.forum-box .panel-heading {
  border: 1px solid #e4e5e7;
  border-bottom: none;
  font-size: 90%;
  background: #f7f9fa;
  padding: 10px 20px;
}
.forum-box .forum-info {
  text-align: right;
  line-height: 20px;
}
.forum-box .forum-info .number {
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-top: 5px;
}
.forum-box .forum-info span {
  font-size: 12px;
}
.forum-box .panel-body .media-image {
  text-align: center;
  margin-right: 10px;
  min-width: 100px;
}
.forum-box .panel-body .media img {
  margin-top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.forum-box .author-info {
  font-size: 12px;
}
.forum-box .forum-comments {
  border: 1px solid #e4e5e7;
  border-left: none;
  border-right: none;
  background: #f7f9fa;
  margin-top: 20px;
  padding: 10px 15px;
}
.forum-box .forum-comments .media img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 0;
  margin-bottom: 10px;
}
.forum-box .forum-comments form {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .forum-box .forum-info {
    text-align: left;
  }
}
ul.mailbox-list,
ul.h-list {
  list-style: none;
  padding: 0;
}
ul.mailbox-list li a,
ul.h-list li a {
  padding: 10px 15px;
  display: block;
}
ul.mailbox-list li a i,
ul.h-list li a i {
  margin-right: 6px;
  display: inline-block;
}
ul.mailbox-list li.active a,
ul.h-list li.active a {
  background: #f7f9fa;
  border: 1px solid #e4e5e7;
  font-weight: 600;
  border-radius: 1px;
}
ul.mailbox-list li:hover a,
ul.h-list li:hover a {
  font-weight: 600;
}
.table-mailbox .radio,
.table-mailbox .checkbox {
  margin: 0;
}
.table-mailbox .radio label,
.table-mailbox .checkbox label {
  cursor: default;
}
.table-mailbox tr.unread td a {
  font-weight: 600;
}
.table-mailbox tr td span.label {
  margin-left: 10px;
}
.table-mailbox tr:first-child td {
  border-top: none;
}
.table-mailbox tr td:first-child {
  width: 33px;
  padding-right: 0;
}
.mailbox-pagination {
  text-align: right;
}
@media (max-width: 768px) {
  .mailbox-pagination {
    text-align: left;
  }
}
.landing-page .navbar-form-custom,
.landing-page .navbar-right,
.landing-page #logo {
  display: block;
}
.landing-page .navbar-default {
  background: #fff;
}
.landing-page .navbar .navbar-brand {
  font-size: 12px;
  height: auto;
  margin-top: 8px;
  padding: 9px 15px;
  font-weight: 700;
  border-radius: 5px;
  color: #fff;
  display: block;
  background-color: #62cb31;
  margin-left: 0;
}
.landing-page .navbar-default .navbar-toggle:hover,
.landing-page .navbar-default .navbar-toggle:focus {
  background: #62cb31;
}
.landing-page .navbar-default .navbar-toggle:hover .icon-bar,
.landing-page .navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #fff !important;
}
.landing-page .navbar-default .navbar-nav > .active > a,
.landing-page .navbar-default .navbar-nav > .active > a:hover,
.landing-page .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
  color: #62cb31;
  border: none;
}
.landing-page .navbar-default li a:hover {
  border-bottom: none;
}
.landing-page .brand-desc {
  color: #455463;
  margin: 17px 10px 0 90px;
  width: 180px;
}
.landing-page .navbar-nav > li > a {
  font-size: 14px;
  font-weight: 600;
  padding: 15px 15px;
  color: #455463;
}
.landing-page .navbar-nav > li,
.landing-page .navbar-nav > li > a {
  height: 55px;
}
.landing-page header {
  /* background: url('../images/landing/header.jpg') 50% 0 no-repeat; */
  height: 480px;
  padding-top: 50px;
  margin-bottom: 30px;
}
.landing-page section {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid #e4e5e7;
}
.landing-page section h2 {
  margin-top: 0;
}
.landing-page section p {
  margin-top: 0px;
}
.landing-page header .container {
  position: relative;
}
.landing-page .heading {
  margin-top: 60px;
  color: #edf0f5;
}
.landing-page .heading h1 {
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 5px;
  letter-spacing: -0.5px;
}
.landing-page h4 {
  font-weight: 600;
}
.landing-page p {
  color: #a7afb8;
}
.landing-page .heading span {
  font-size: 13px;
  font-weight: 600;
}
.landing-page .heading p.small {
  margin-top: 10px;
  color: #a7afb8;
  max-width: 260px;
}
.landing-page .heading-image {
  position: absolute;
  right: 60px;
  top: 120px;
  text-align: right;
}
.landing-page .heading-image p {
  color: #a7afb8;
  margin-bottom: 5px;
}
.landing-page .heading-image img {
  -webkit-box-shadow: 0px 0px 8px 0px #333333;
  -moz-box-shadow: 0px 0px 8px 0px #333333;
  box-shadow: 0px 0px 8px 0px #333333;
  margin-left: 12px;
  margin-top: 12px;
  width: 180px;
  border-radius: 1px;
}
.landing-page .navy-link {
  background: #455463;
  color: #fff;
}
.landing-page .icon-big {
  font-size: 70px;
}
.landing-page .team-member img {
  height: 72px;
}
.landing-page .social-icon a {
  background: #62cb31;
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  height: 28px;
  width: 28px;
  display: block;
  border-radius: 4px;
}
.landing-page .list-inline > li {
  padding: 0;
}
.landing-page .social-icon a:hover {
  background: #5abb2d;
}
.landing-page .pricing-plan {
  margin: 20px 10px 0 10px;
  border-radius: 4px;
  padding-bottom: 20px;
  background: #fff;
  border: 1px solid #e4e5e7;
}
.landing-page .pricing-plan li {
  padding: 10px 16px;
  text-align: center;
  color: #aeaeae;
}
.landing-page .pricing-plan .pricing-price span {
  font-weight: 700;
  font-size: 30px;
  color: #62cb31;
}
.landing-page .pricing-title .label {
  font-size: 12px;
}
.landing-page li.pricing-desc {
  font-size: 13px;
  border-top: none;
  padding: 20px 16px 0px 16px;
}
.landing-page li.pricing-title {
  color: #455463;
  padding: 25px 10px 0px 10px;
  border-radius: 4px 4px 0 0;
  font-size: 22px;
  font-weight: 600;
}
.landing-page .client {
  color: #a7afb8;
  background: #f7f9fa;
  padding: 30px 10px;
  margin: 0px 0 20px 0;
  text-transform: uppercase;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .landing-page .heading-image {
    display: none;
  }
  .landing-page .heading {
    text-align: center;
  }
  .landing-page .heading p.small {
    margin: 10px auto;
  }
  .landing-page .brand-desc {
    display: none;
  }
  .landing-page .navbar .navbar-brand {
    margin-left: 10px;
  }
}
.xlModal > .modal-dialog {
  max-width: 900px !important;
  min-width: 800px !important;   
   width: 60% !important;
  /*margin-top: 0px !important; */
}

