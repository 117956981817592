/* You can add global styles to this file, and also import other style files */
@import url(//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en);
@import "./assets/styles/style.css";
@import "~@angular/cdk/overlay-prebuilt.css";

ngb-modal-window {
  opacity: 1 !important;
}

ngb-modal-backdrop {
  z-index: 1 !important;
  opacity: 0.5 !important;
}

.modal-dialog {
  transform: none !important;
  position: relative !important;
  width: auto !important;
  margin: .5rem;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog {
  @media (min-width: 768px) {
    width: 600px !important;
    margin: auto !important;
  }
}

.center {
  display: block;
  text-align: -webkit-center;
}

.modal-dialog-centered {
  @media (min-width: 576px) {
    min-height: calc(100% - 3.5rem);
  }
}

.modal-content {
  width: 100%;
  position:relative;
  min-height: 300px;
  // padding: 20px 0;
}

.modal-content p{
  // min-height: 150px;
  // border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 60px;
  padding: 5px;
}

.modal-header {
 // padding: 40px 30px;
  background: #f7f9fa;
  min-height: 16.42857143px;
  border-bottom: 1px solid #e5e5e5;
  align-items: flex-start;
  justify-content: space-between;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  // position: absolute;
  top: 0;
  width: 100%;
}

.modal.fade {
  margin-top: 80px !important;
}

.modal-backdrop.fade {
  opacity: .75 !important;
}

.multiselect-dropdown {
  display: inline-block !important;
  width: auto !important;
  vertical-align: middle !important;
}

.dropdown-btn {
  border-color: #e4e5e7 !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  color: #655dc6 !important;
  border: 2px solid #655dc6 !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  background: #655dc6 !important;
}

.is-invalid {
  border-color: #ff5454 !important;
}

.multiselect-dropdown {
  .dropdown-btn {
    .dropdown-down {
      position: relative !important;
      top: -1px !important;
      left: 8px !important;
      border-top: 6px solid #555555 !important;
      border-left: 3px solid transparent !important;
      border-right: 3px solid transparent !important;
      margin-left: 12px !important;
    }

    .dropdown-up {
      position: relative !important;
      top: -1px !important;
      left: 8px !important;
      border-bottom: 6px solid #555555 !important;
      border-left: 3px solid transparent !important;
      border-right: 3px solid transparent !important;
      margin-left: 12px !important;
    }
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.invalid-form-errors {
  color: #ff5454;
}

.ngb-datepicker-custom {
  position: fixed !important;
  top: var(--top) !important;
  left: var(--left) !important
}

.ngb-dp-day.hidden {
  display: block !important;
}

.wraped-text {
  word-break: break-all;
}

.footer {
  margin-top: 20px;
  color: #7f8fa4;
  font-size: 10px;
  text-align: center;
  
}

#logo {
  padding: 0;
}

.pfi-box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-default {
  background-color: #ededed !important;
}

.progress-spinner {
  position: absolute !important;
  left: 34% !important;
  top: 34% !important;
  z-index: 9999 !important;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.lds-spinner {
  position: relative;
}

.lds-spinner div {
  left: 90px;
  top: -25px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #535151;
  width: 20px;
  height: 50px;
  border-radius: 40%;
  -webkit-transform-origin: 10px 125px;
  transform-origin: 10px 125px;
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.lds-spinner {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

input:focus {
  &::-webkit-input-placeholder {
     color: transparent;
     -webkit-transition: color 0.2s ease;
     transition: color 0.2s ease;
  }

  &:-moz-placeholder { /* Firefox 18- */
     color: transparent;
     -webkit-transition: color 0.2s ease;
     transition: color 0.2s ease;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
     color: transparent;
     -webkit-transition: color 0.2s ease;
     transition: color 0.2s ease;
  }

  &:-ms-input-placeholder {  
     color: transparent;
     -webkit-transition: color 0.2s ease;
     transition: color 0.2s ease;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  #CustomReports_List_filter label{
    color: #6a6c6f;
  }

  body {
    color: #6a6c6f;
  }
}
